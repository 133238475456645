import Link from "next/link";
import React, { forwardRef } from "react";

const MyLink = forwardRef((props, ref) => {
  let { href, children, iconName, active, ...rest } = props;
  return (
    (<Link href={href} passHref ref={ref} {...rest}>

      {children}

    </Link>)
  );
});
export default MyLink;
MyLink.displayName = "MyLink";
