import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import useSWR from "swr";

import Footer from "../common/Footer";
import { AppLoadingSpinner } from "../common/LoadingSpinner";
import NavBar from "../common/NavBar";
import PollsBannerAlert from "../common/PollsBannerAlert";
import { ScrollToTop } from "../common/ScrollToTop";
import Sponsors from "../common/Sponsors";
import { kWebsitesURL } from "../../utils/constants/apiUrls";
import SocialMediaIcons from "../common/SocialMediaIcons";
import FabuCensusAd from "../common/FabuCensusAd";
import AppStoreIcons from "../common/AppStoreIcons";
import { GUEST_TOKEN } from "../../utils/constants/names";
import { ONE_WEEK_REFRESH_INTERVAL_MILLISECONDS } from "../../utils/constants/numbers";
import { pageview } from "../../utils/helpers/googleAnalytics";
import WebsiteCookieConsent from "../common/WebsiteCookieConsent";

function SiteLayout({ children }) {
  const router = useRouter();

  const [loading, setLoading] = useState(false);

  const { data: websiteUrls, error } = useSWR(
    [
      kWebsitesURL,
      process.env.GUEST_USER_TOKEN ? process.env.GUEST_USER_TOKEN : GUEST_TOKEN,
    ],

    {
      revalidateOnMount: true,
      refreshInterval: ONE_WEEK_REFRESH_INTERVAL_MILLISECONDS,
      focusThrottleInterval: ONE_WEEK_REFRESH_INTERVAL_MILLISECONDS,
    }
  );

  const websiteUrlList = websiteUrls?.data;

  let hostname;

  useEffect(() => {
    const handleRouteChange = (url) => {
      setLoading(false);
      pageview(url);
    };

    router.events.on("routeChangeError", (e) => setLoading(false));
    router.events.on("routeChangeStart", (e) => setLoading(true));
    router.events.on("routeChangeComplete", (e) => setLoading(false));

    return () => {
      router.events.off("routeChangeError", (e) => setLoading(false));
      router.events.off("routeChangeStart", (e) => setLoading(true));
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router]);

  return (
    <div className="h-screen">
      <NavBar
        tvUrl={websiteUrlList?.kotoko_tv_url?.live_tv_url}
        ticketsUrl={websiteUrlList?.ticket_website_url?.ticket_website_url}
        shopUrl={websiteUrlList?.shop_website_url?.shop_url}
      />

      {loading ? <AppLoadingSpinner /> : <>{children}</>}

      {/* <PollsBannerAlert /> */}

      <ScrollToTop />

      <WebsiteCookieConsent />

      <SocialMediaIcons />

      <FabuCensusAd
        fabuCensusUrl={
          websiteUrlList?.membership_website_url?.membership_website_url
        }
      />

      <AppStoreIcons />

      <Sponsors />

      <Footer />
    </div>
  );
}

export default SiteLayout;
