import Image from "next/legacy/image";
import Link from "next/link";
import useSWR from "swr";

import { kSponsorsURL } from "../../utils/constants/apiUrls";
import { blurDataURL } from "../../utils/constants/images";
import { GUEST_TOKEN } from "../../utils/constants/names";
import { ONE_WEEK_REFRESH_INTERVAL_MILLISECONDS } from "../../utils/constants/numbers";

const Sponsors = () => {
  const { data: sponsors, error } = useSWR(
    [
      kSponsorsURL,
      process.env.GUEST_USER_TOKEN ? process.env.GUEST_USER_TOKEN : GUEST_TOKEN,
    ],
    {
      revalidateOnMount: true,
      refreshInterval: ONE_WEEK_REFRESH_INTERVAL_MILLISECONDS,
      focusThrottleInterval: ONE_WEEK_REFRESH_INTERVAL_MILLISECONDS,
    }
  );

  const sponsorList = sponsors?.data;

  if (error) return <div></div>;
  if (!sponsorList) return <div></div>;

  return <>
    {/* <!--Sponsors --> */}

    <section>
      <div className="max-w-7xl mx-auto pt-10 px-4 sm:px-6 lg:px-8">
        <div>
          <div className="relative">
            <div
              className="absolute inset-0 flex items-center"
              aria-hidden="true"
            >
              <div className="w-full border-t border-black"></div>
            </div>
            <div className="relative flex justify-center"></div>
          </div>

          <div className="mt-16 grid grid-cols-3 gap-0.5 md:grid-cols-4 lg:mt-8">
            {sponsorList.map((sponsor) => {
              return (
                (<Link
                  href={sponsor.sponsor_website_url}
                  key={sponsor.id}
                  target="_blank"
                  rel="noopener noreferrer">

                  <div className="relative col-span-1 flex justify-center py-8 sm:py-10 px-8">
                    <Image
                      src={sponsor.sponsor_logo}
                      alt={sponsor.sponsor_name}
                      layout="fill"
                      objectFit="contain"
                      quality={100}
                      placeholder="blur"
                      blurDataURL={blurDataURL}
                      className="max-h-12 pt-5"
                    />
                  </div>

                </Link>)
              );
            })}
          </div>
        </div>
      </div>
    </section>
  </>;
};

export default Sponsors;
