import { useEffect, useState, Fragment } from "react";

import { useRouter } from "next/router";
import { SearchIcon } from "@heroicons/react/outline";
import { Combobox, Transition, Dialog } from "@headlessui/react";
import Image from "next/legacy/image";
import useSWR from "swr";

import { blurDataURL } from "../../utils/constants/images";
import { ONE_WEEK_REFRESH_INTERVAL_MILLISECONDS } from "../../utils/constants/numbers";
import {
  gallery,
  GUEST_TOKEN,
  news,
  playerPoll,
  textPoll,
} from "../../utils/constants/names";
import { kFeedSearchURL } from "../../utils/constants/apiUrls";
import {
  GALLERIES_LIST_URL,
  NEWS_LIST_URL,
  PLAYER_POLL_URL,
  TEXT_POLL_URL,
} from "../../utils/constants/websiteUrls";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const typeTextStyles = {
  news: "text-green-800",
  gallery: "text-yellow-800",
  text_poll: "text-indigo-800",
  player_poll: "text-red-800",
};

export function Search() {
  const { data, error } = useSWR(
    [
      kFeedSearchURL,
      process.env.GUEST_USER_TOKEN ? process.env.GUEST_USER_TOKEN : GUEST_TOKEN,
    ],
    {
      fallbackData: {
        data: [{}],
      },
      revalidateOnMount: true,
      refreshInterval: ONE_WEEK_REFRESH_INTERVAL_MILLISECONDS,
      focusThrottleInterval: ONE_WEEK_REFRESH_INTERVAL_MILLISECONDS,
    }
  );

  const router = useRouter();

  const searchData = data?.data;

  const [selected, setSelected] = useState(searchData[0]);
  const [query, setQuery] = useState("");

  let [isSearchCombo, setIsSearchCombo] = useState(false);

  function closeIsSearchComboModal() {
    setIsSearchCombo(false);
  }

  function openIsSearchComboModal() {
    setIsSearchCombo(true);
  }

  const filteredSearchData =
    query === ""
      ? []
      : searchData.filter((item) =>
          item.title
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  useEffect(() => {}, []);

  return (
    <>
      <button
        type="button"
        className="p-2 text-white hover:border-red-300 dark:hover:border-green-300 hover:text-red-300 hover:dark:text-green-300"
      >
        <span className="sr-only">Search</span>
        <SearchIcon
          className="w-6 h-6"
          aria-hidden="true"
          onClick={openIsSearchComboModal}
        />
      </button>

      <Transition appear show={isSearchCombo} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 flex items-start justify-center pt-16 sm:pt-24"
          onClose={closeIsSearchComboModal}
          open={isSearchCombo}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-slate-900/25 backdrop-blur transition-opacity opacity-100" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="fixed inset-0 z-50 flex items-start justify-center pt-16 sm:pt-24">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="relative w-full max-w-lg transform px-4 transition-all opacity-100 scale-100">
                  <div>
                    <div className="text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <div className="">
                        <div className="w-full">
                          <Combobox value={selected} onChange={setSelected}>
                            <div className="relative shadow-md">
                              <div className="overflow-hidden rounded-md bg-white shadow-sm">
                                <Combobox.Input
                                  className="block w-full appearance-none bg-transparent py-4 text-base text-slate-900 placeholder:text-slate-600 focus:outline-none focus:border-transparent sm:text-sm sm:leading-6"
                                  // displayValue={(person) => person.name}
                                  onChange={(event) =>
                                    setQuery(event.target.value)
                                  }
                                  placeholder="Search News, Galleries, Polls"
                                />
                                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                                  <SearchIcon
                                    className="pointer-events-none absolute top-4 right-4 h-6 w-6 fill-slate-50 text-slate-400"
                                    aria-hidden="true"
                                  />
                                </Combobox.Button>
                              </div>
                              <Transition
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                                afterLeave={() => setQuery("")}
                              >
                                <Combobox.Options className="absolute max-h-60 w-full overflow-auto bg-white text-base sm:text-sm divide-y divide-slate-200 overflow-y-auto  rounded-md leading-6">
                                  {filteredSearchData.length === 0 &&
                                  query !== "" ? (
                                    <div className="bg-slate-50 text-center relative cursor-default select-none py-10 px-10">
                                      <h2 className="font-semibold text-slate-900 break-all">
                                        {`No result found for "${query}"`}
                                      </h2>
                                      <p className="mt-2 text-sm leading-6 text-slate-600">
                                        {
                                          "We can’t find anything with that term at the moment, try searching something else."
                                        }
                                      </p>
                                    </div>
                                  ) : (
                                    filteredSearchData.map((item) => (
                                      <Combobox.Option
                                        onClick={() => {
                                          const type = item.type;

                                          closeIsSearchComboModal();

                                          if (type === news) {
                                            router.push(
                                              `${NEWS_LIST_URL}/${item.slug}`
                                            );
                                          }

                                          if (type === gallery) {
                                            router.push(
                                              `${GALLERIES_LIST_URL}/${item.slug}`
                                            );
                                          }

                                          if (type === playerPoll) {
                                            router.push(
                                              `${PLAYER_POLL_URL}/${item.slug}`
                                            );
                                          }

                                          if (type === textPoll) {
                                            router.push(
                                              `${TEXT_POLL_URL}/${item.slug}`
                                            );
                                          }
                                        }}
                                        key={item.slug}
                                        className={({ active }) =>
                                          `flex items-start justify-between p-4 cursor-pointer ${
                                            active
                                              ? "bg-gray-200 text-white"
                                              : "text-gray-900"
                                          }`
                                        }
                                        value={item}
                                      >
                                        {({ selected, active }) => (
                                          <>
                                            <span
                                              className={`block truncate ${
                                                selected
                                                  ? "font-medium"
                                                  : "font-normal"
                                              }`}
                                            >
                                              <div className="flex items-center justify-center">
                                                <div className="">
                                                  <Image
                                                    src={item.thumbnail}
                                                    width={60}
                                                    height={60}
                                                    objectFit="cover"
                                                    alt="Player Avatar"
                                                    className="rounded-md"
                                                    placeholder="blur"
                                                    blurDataURL={blurDataURL}
                                                  />
                                                </div>
                                                <div className="flex flex-col ml-3 w-[80%] text-left">
                                                  <h1 className="text-base text-gray-900 truncate">
                                                    {item.title}
                                                  </h1>
                                                  <p className="text-xs text-gray-500 truncate">
                                                    {item.caption}
                                                  </p>
                                                  <span
                                                    className={classNames(
                                                      typeTextStyles[item.type],
                                                      "text-[10px]"
                                                    )}
                                                  >
                                                    {item.type === gallery
                                                      ? "Gallery"
                                                      : item.type === news
                                                      ? "News"
                                                      : "Poll"}
                                                  </span>
                                                </div>
                                              </div>
                                            </span>
                                          </>
                                        )}
                                      </Combobox.Option>
                                    ))
                                  )}
                                </Combobox.Options>
                              </Transition>
                            </div>
                          </Combobox>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
