import Link from "next/link";
import useSWR from "swr";

import { kNewPollNotification } from "../../utils/constants/apiUrls";
import { GUEST_TOKEN } from "../../utils/constants/names";
import { ONE_DAY_REFRESH_INTERVAL_MILLISECONDS } from "../../utils/constants/numbers";
import { POLL_LIST_URL } from "../../utils/constants/websiteUrls";
import { checkIfObjectValuesAreEmpty } from "../../utils/helpers/helpers";

function PollsBannerAlert() {
  const { data: polls, error } = useSWR(
    [
      kNewPollNotification,
      process.env.GUEST_USER_TOKEN ? process.env.GUEST_USER_TOKEN : GUEST_TOKEN,
    ],
    {
      fallbackData: {
        data: {
          player_poll: {
            title: "",
            caption: "",
            slug: "",
          },
          text_poll: {
            title: "",
            caption: "",
            slug: "",
          },
        },
      },
      revalidateOnMount: true,
      refreshInterval: ONE_DAY_REFRESH_INTERVAL_MILLISECONDS,
      focusThrottleInterval: ONE_DAY_REFRESH_INTERVAL_MILLISECONDS,
    }
  );

  const { player_poll: playerPoll, text_poll: textPoll } = polls?.data;

  const isPlayerPollEmpty = checkIfObjectValuesAreEmpty(playerPoll);
  const isTextPollEmpty = checkIfObjectValuesAreEmpty(textPoll);

  if (error) return <div></div>;
  if (!polls) return <div></div>;

  if (!isPlayerPollEmpty && !isTextPollEmpty) {
    return <PollBanner poll={playerPoll} />;
  }

  if (!isPlayerPollEmpty && isTextPollEmpty) {
    return <PollBanner poll={playerPoll} />;
  }

  if (isPlayerPollEmpty && !isTextPollEmpty) {
    return <PollBanner poll={textPoll} />;
  }
}

export default PollsBannerAlert;

const PollBanner = (poll) => {
  const { title, caption, slug } = poll?.poll;

  return (
    <div className="fixed bottom-14 inset-x-0 pb-2 sm:pb-5 z-10 alert alert-dismissible fade show">
      <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
        <div className="p-2 rounded-md bg-red-600 dark:bg-green-800 shadow-lg sm:p-3">
          <div className="flex items-center justify-between flex-wrap">
            <div className="w-0 flex-1 flex items-center">
              <span className="flex p-2 rounded-md bg-red-800 dark:bg-green-900">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 576 512"
                  className="w-6 h-6 text-white"
                >
                  <path
                    fill="currentColor"
                    d="M552 64H448V24c0-13.3-10.7-24-24-24H152c-13.3 0-24 10.7-24 24v40H24C10.7 64 0 74.7 0 88v56c0 35.7 22.5 72.4 61.9 100.7 31.5 22.7 69.8 37.1 110 41.7C203.3 338.5 240 360 240 360v72h-48c-35.3 0-64 20.7-64 56v12c0 6.6 5.4 12 12 12h296c6.6 0 12-5.4 12-12v-12c0-35.3-28.7-56-64-56h-48v-72s36.7-21.5 68.1-73.6c40.3-4.6 78.6-19 110-41.7 39.3-28.3 61.9-65 61.9-100.7V88c0-13.3-10.7-24-24-24zM99.3 192.8C74.9 175.2 64 155.6 64 144v-16h64.2c1 32.6 5.8 61.2 12.8 86.2-15.1-5.2-29.2-12.4-41.7-21.4zM512 144c0 16.1-17.7 36.1-35.3 48.8-12.5 9-26.7 16.2-41.8 21.4 7-25 11.8-53.6 12.8-86.2H512v16z"
                  />
                </svg>
              </span>
              <p className="ml-3 font-medium text-white truncate">
                <span className="md:hidden"> {title}</span>
                <span className="hidden md:inline">{caption}</span>
              </p>
            </div>
            <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
              <Link
                href={POLL_LIST_URL}
                className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-red-600 dark:text-green-800 bg-white hover:bg-indigo-50">
                
                  Vote now
                
              </Link>
            </div>
            <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
              <a
                href="#"
                className="-mr-1 flex p-2 rounded-md hover:bg-red-500 dark:hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-white"
                data-bs-dismiss="alert"
                aria-label="Close"
              >
                <span className="sr-only">Dismiss</span>

                <svg
                  className="h-6 w-6 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
