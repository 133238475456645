// Seconds
export const TEN_MINUTES_REFRESH_INTERVAL_SECONDS = 600;
export const THIRTY_MINUTE_REFRESH_INTERVAL_SECONDS = 1800;
export const ONE_HOUR_REFRESH_INTERVAL_SECONDS = 3600;
export const ONE_WEEK_REFRESH_INTERVAL_SECONDS = 604800;
export const ONE_DAY_REFRESH_INTERVAL_SECONDS = 86400;
export const THREE_DAYS_REFRESH_INTERVAL_SECONDS = 259200;
export const SIX_DAYS_REFRESH_INTERVAL_SECONDS = 518400;

export const ONE_MONTH_REFRESH_INTERVAL_SECONDS = 2592000;
export const TWO_MONTH_REFRESH_INTERVAL_SECONDS = 5184000;
export const THREE_MONTH_REFRESH_INTERVAL_SECONDS = 7776000;
export const SIX_MONTHS_REFRESH_INTERVAL_SECONDS = 15552000;
export const TWELVE_MONTHS_REFRESH_INTERVAL_SECONDS = 31104000;

// Milliseconds
export const TEN_MINUTE_REFRESH_INTERVAL_MILLISECONDS = 600000;
export const THIRTY_MINUTE_REFRESH_INTERVAL_MILLISECONDS = 1800000;
export const FOURTY_MINUTE_REFRESH_INTERVAL_MILLISECONDS = 2400000;
export const ONE_HOUR_REFRESH_INTERVAL_MILLISECONDS = 3600000;
export const ONE_HOUR_FIFTEEN_MINUTES_REFRESH_INTERVAL_MILLISECONDS = 4500000;
export const ONE_WEEK_REFRESH_INTERVAL_MILLISECONDS = 604800000;
export const ONE_DAY_REFRESH_INTERVAL_MILLISECONDS = 86400000;
export const ONE_MONTH_REFRESH_INTERVAL_MILLISECONDS = 2592000000;
