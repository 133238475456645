import { Fragment, useState, useEffect } from "react";

import Image from "next/legacy/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useSession, signOut } from "next-auth/react";

import { Dialog, Popover, Transition, Menu } from "@headlessui/react";
import { MenuIcon, XIcon, ExclamationIcon } from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { MdSpaceDashboard } from "react-icons/md";
import { FaSignOutAlt, FaUser, FaUserCog } from "react-icons/fa";

import {
  NEXT_AUTH_LOGIN_URL,
  INDEX_URL,
  DASHBOARD_URL,
  MY_KOTOKO_URL,
  USER_PROFILE_UPDATE,
  NEXT_AUTH_LOGOUT_URL,
  TICKETS_URL,
  SHOP_URL,
  KOTOKO_TV_URL,
  NEWS_LIST_URL,
  PLAYER_LIST_URL,
  FIXTURES_URL,
  FANS_URL,
  CLUB_URL,
  PEOPLE_LIST_URL,
} from "../../utils/constants/websiteUrls.js";
import {
  asante_kotoko_logo_svg,
  defaultUserAvatar,
} from "../../utils/constants/images.js";
import MyLink from "./MyLink.jsx";
import { Search } from "./Search.jsx";
import { ThemeSelector } from "./ThemeSelector.jsx";

function NavBar({ tvUrl, shopUrl, ticketsUrl }) {
  const menuItems = [
    { name: "News", href: NEWS_LIST_URL, openBlank: false },
    {
      name: "Kotoko TV",
      href: tvUrl ? tvUrl : KOTOKO_TV_URL,
      openBlank: true,
    },
    { name: "Fixtures", href: FIXTURES_URL, openBlank: false },
    { name: "Players", href: PLAYER_LIST_URL, openBlank: false },
    { name: "People", href: PEOPLE_LIST_URL, openBlank: false },
    { name: "Club", href: CLUB_URL, openBlank: false },
    { name: "Fans", href: FANS_URL, openBlank: false },
    // {
    //   name: "Tickets",
    //   href: ticketsUrl ? ticketsUrl : TICKETS_URL,
    //   openBlank: true,
    // },
    // {
    //   name: "Shop",
    //   href: shopUrl ? shopUrl : SHOP_URL,
    //   openBlank: true,
    // },
  ];

  const userMenuItems = [
    {
      name: "Dashboard",
      href: DASHBOARD_URL,
      icon: MdSpaceDashboard,
      border: true,
      onClick: "",
    },
    {
      name: "My Kotoko",
      href: MY_KOTOKO_URL,
      icon: FaUser,
      border: false,
      onClick: "",
    },
    {
      name: "Account Settings",
      href: USER_PROFILE_UPDATE,
      icon: FaUserCog,
      border: true,
      onClick: "",
    },
    {
      name: "Log out",
      href: "",
      icon: FaSignOutAlt,
      border: false,
      onClick: "",
    },
  ];

  const router = useRouter();
  const [scrollY, setScrollY] = useState(0);
  const [open, setOpen] = useState(false);

  const currentRoute = router.asPath;

  const { data: session } = useSession();

  const user = session?.user;
  const userProfile = session?.userProfile;

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    handleScroll();

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return <>
    {/* Mobile menu */}

    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-40 lg:hidden" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 flex z-40">
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <Dialog.Panel className="relative max-w-xs w-full bg-white shadow-xl pb-12 flex flex-col overflow-y-auto">
              <div className="px-4 pt-5 pb-2 flex">
                <button
                  type="button"
                  className="-m-2 p-2 rounded-md inline-flex items-center justify-center text-gray-400"
                  onClick={() => setOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>

              {/* Links */}
              <div className="border-t border-gray-200 py-6 px-4 space-y-6">
                {menuItems.map((menuItem, index) => (
                  <div key={index} className="flow-root">
                    <Link
                      href={menuItem.href}
                      className={classNames(
                        currentRoute === menuItem.href
                          ? "border-red-900 text-red-900 dark:border-green-900 dark:text-green-900"
                          : "border-transparent",
                        "-m-2 p-2 block font-medium text-gray-900 hover:text-red-900 dark:hover:text-green-900"
                      )}
                      onClick={() => {
                        setOpen(false);
                      }}
                      target={menuItem.openBlank ? "_blank" : ""}
                      rel={menuItem.openBlank ? "noopener noreferrer" : ""}>

                      {menuItem.name}

                    </Link>
                  </div>
                ))}
              </div>

              {/* Log out  */}
              {session && (
                <div className="border-t border-gray-200 py-6 px-4 space-y-6">
                  <div className="flow-root">
                    <a
                      className={classNames(
                        currentRoute === NEXT_AUTH_LOGOUT_URL
                          ? "text-red-900 dark:text-green-900"
                          : "border-transparent",
                        "-m-2 p-2 block font-medium text-gray-900 hover:text-red-900 dark:hover:text-green-900 transition duration-150 ease-in-out"
                      )}
                      onClick={() => {
                        setOpen(false);
                      }}
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop"
                    >
                      Log out
                    </a>
                  </div>
                </div>
              )}

              {/* Log in  */}
              {!session && (
                <div className="border-t border-gray-200 py-6 px-4 space-y-6">
                  <div className="flow-root">
                    <Link
                      href={NEXT_AUTH_LOGIN_URL}
                      className={classNames(
                        currentRoute === NEXT_AUTH_LOGIN_URL
                          ? "text-red-900 dark:text-green-900"
                          : "border-transparent",
                        "-m-2 p-2 block font-medium text-gray-900 hover:text-red-900 dark:hover:text-green-900"
                      )}
                      onClick={() => {
                        setOpen(false);
                      }}>
                      
                        Log in
                      
                    </Link>
                  </div>
                </div>
              )}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>

    <header>
      {/* Top navigation */}
      <nav
        aria-label="Top"
        className={classNames(
          scrollY > 20
            ? " backdrop-blur [@supports(backdrop-filter:blur(0))]:bg-red-600/90 dark:backdrop-blur dark:[@supports(backdrop-filter:blur(0))]:bg-green-800/90"
            : "bg-transparent",
          "fixed z-20 bg-opacity-90 w-full transition-all ease-linear duration-500"
        )}
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="h-16 flex items-center">
            <button
              type="button"
              className="bg-transparent p-2 rounded-md text-white lg:hidden"
              onClick={() => setOpen(true)}
            >
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </button>

            {/* Logo */}
            <div className="ml-4 flex lg:ml-0">
              <Link href={INDEX_URL}>

                <span className="sr-only">Asante Kotoko SC</span>
                <div className="flex items-center">
                  <Image
                    src={asante_kotoko_logo_svg}
                    alt="Asante Kotoko Logo"
                    objectFit="contain"
                    priority
                    quality={100}
                    height={40}
                    width={40}
                  />
                </div>

              </Link>
            </div>

            <span
              className="h-6 w-px ml-4 bg-white hidden sm:hidden md:hidden lg:block xl:block 2xl:block"
              aria-hidden="true"
            ></span>

            {/* Flyout menus */}
            <Popover.Group className="hidden lg:ml-8 lg:block lg:self-stretch">
              <div className="h-full flex space-x-8">
                {menuItems.map((menuItem, index) => (
                  (<Link
                    key={index}
                    href={menuItem.href}
                    onClick={() => {}}
                    className={classNames(
                      currentRoute === menuItem.href
                        ? "hover:border-red-300 dark:hover:border-green-300 hover:text-red-300 hover:dark:text-green-300"
                        : "border-transparent text-white",
                      "relative z-10 flex items-center transition-colors ease-out duration-200 text-sm font-medium border-b-2 -mb-px pt-px hover:border-red-300 dark:hover:border-green-300 hover:text-red-300 hover:dark:text-green-300"
                    )}
                    target={menuItem.openBlank ? "_blank" : ""}
                    rel={menuItem.openBlank ? "noopener noreferrer" : ""}>

                    {menuItem.name}

                  </Link>)
                ))}
              </div>
            </Popover.Group>

            <div className="ml-auto flex items-center">
              {!session && (
                <div className="hidden lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6">
                  <Link
                    href={NEXT_AUTH_LOGIN_URL}
                    className={classNames(
                      currentRoute === NEXT_AUTH_LOGIN_URL
                        ? "hover:border-red-300 dark:hover:border-green-300 hover:text-red-300 hover:dark:text-green-300"
                        : "border-transparent text-white ",
                      "text-sm font-medium text-white hover:border-red-300 dark:hover:border-green-300 hover:text-red-300 hover:dark:text-green-300"
                    )}
                    onClick={() => {}}>
                    
                      Log in
                    
                  </Link>
                </div>
              )}

              <ThemeSelector className="relative z-10 sm:ml-4 mr-2" />

              {/* Search */}
              <div className="flex lg:ml-2">
                <Search />
              </div>

              {session && (
                <>
                  <span
                    className="h-6 w-px ml-4 bg-white invisible sm:visible"
                    aria-hidden="true"
                  ></span>

                  <div className="flex lg:ml-2">
                    {/* Profile dropdown */}
                    <Menu as="div" className="sm:ml-3 relative">
                      <div>
                        <Menu.Button className="max-w-xs bg-transparent rounded-full lg:rounded-md flex items-center text-sm lg:p-2 ">
                          <Image
                            src={
                              userProfile?.profile_picture
                                ? userProfile?.profile_picture
                                : defaultUserAvatar
                            }
                            alt="User Profile Picture"
                            objectFit="cover"
                            priority
                            className="rounded-full"
                            width={32}
                            height={32}
                          />

                          <ChevronDownIcon
                            className="items-center flex-shrink-0 ml-0 h-5 w-5 text-white lg:block"
                            aria-hidden="true"
                          />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="origin-top-right absolute top-full right-0 w-60 mt-1 -mr-0.5 sm:-mr-3.5 rounded-md shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none font-normal text-sm text-gray-900">
                          <Menu.Item>
                            {
                              <p className="py-3 px-3.5 truncate border-b-2">
                                <span className="block mb-0 5 text-xs text-gray-500">
                                  Logged in as
                                </span>
                                <span className="font-semibold text">
                                  {userProfile?.email
                                    ? userProfile?.email
                                    : user?.email}
                                </span>
                              </p>
                            }
                          </Menu.Item>

                          {userMenuItems.map((userMenuItem, index) => (
                            <Menu.Item key={userMenuItem.name}>
                              {({ active }) => {
                                return (
                                  <MyLink
                                    href={userMenuItem.href}
                                    className={classNames(
                                      active
                                        ? "bg-gray-100 text-red-600 dark:text-green-800"
                                        : "text-gray-700",
                                      "block px-4 py-2 text-sm",

                                      userMenuItem.border ? "border-b-2" : ""
                                    )}
                                    onClick={userMenuItem.onClick}
                                    data-bs-toggle={
                                      userMenuItem.name === "Log out"
                                        ? "modal"
                                        : ""
                                    }
                                    data-bs-target={
                                      userMenuItem.name === "Log out"
                                        ? "#staticBackdrop"
                                        : ""
                                    }
                                  >
                                    <userMenuItem.icon
                                      className={classNames(
                                        active
                                          ? "text-red-500 dark:text-green-800"
                                          : "text-gray-400 ",
                                        "mr-3 inline-flex h-[18px] w-[18px]"
                                      )}
                                      aria-hidden="true"
                                    />
                                    {userMenuItem.name}
                                  </MyLink>
                                );
                              }}
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </nav>
    </header>

    {/* Log out Modal */}
    <div
      className="modal fade fixed top-20 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
      id="staticBackdrop"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog relative w-auto">
        <div className="modal-content border-none shadow-lg relative flex flex-col bg-white w-full pointer-events-autobg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden  transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
          <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
            <button
              type="button"
              className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 dark:focus:ring-green-700"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span className="sr-only">Close</span>
              <XIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="sm:flex sm:items-start">
            <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 dark:bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
              <ExclamationIcon
                className="h-6 w-6 text-red-600 dark:text-green-800"
                aria-hidden="true"
              />
            </div>
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h5
                className="text-lg leading-6 font-medium text-gray-900"
                id="exampleModalLabel"
              >
                Ready to leave?
              </h5>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  {
                    'Are you sure you want to log out? Select "Log out" below if you are ready to end your current session.'
                  }
                </p>
              </div>
            </div>
          </div>
          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 dark:bg-green-800 text-base font-medium text-white hover:bg-red-700 dark:hover:bg-green-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 dark:focus:ring-green-700 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={() => signOut()}
            >
              Log out
            </button>
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 dark:focus:ring-green-700 sm:mt-0 sm:w-auto sm:text-sm"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </>;
}

export default NavBar;
