import axios from "axios";
import { kAsanteKotokoPortalApiBaseUrl } from "../constants/apiUrls";
import { axiosHeaderConfig, axiosFormHeaderConfig } from "../helpers/helpers";

export const apiWrapper = {
  get,
  get_with_optional_header,
  getQueryWithParameters,
  post,
  put,
  _delete,
};

const axiosInstance = axios.create({
  baseURL: kAsanteKotokoPortalApiBaseUrl,
});

async function get(url, accessToken) {
  try {
    const response = await axiosInstance.get(
      url,
      axiosHeaderConfig(accessToken)
    );

    return Promise.resolve(response.data);
  } catch (err) {
    //console.log(`ERROR FROM GETTING DATA : ${err.message}`);
    return Promise.reject(err);
  }
}

async function get_with_optional_header(url, header) {
  try {
    const response = await axiosInstance.get(url, header);

    return Promise.resolve(response.data);
  } catch (err) {
    //console.log(`ERROR FROM GETTING DATA : ${err.message}`);
    return Promise.reject(err);
  }
}

async function getQueryWithParameters(url, reqParams, accessToken) {
  try {
    const response = await axiosInstance.get(
      url,
      axiosHeaderConfig(accessToken),
      { params: reqParams }
    );

    return Promise.resolve(response.data);
  } catch (err) {
    //console.log(`ERROR FROM GETTING DATA : ${err.message}`);
    return Promise.reject(err);
  }
}

async function post(url, accessToken, data) {
  try {
    const response = await axiosInstance.post(
      url,
      data,
      axiosHeaderConfig(accessToken)
    );

    return Promise.resolve(response.data);
  } catch (err) {
    //console.log(`ERROR FROM POSTING DATA : ${err.message}`);
    return Promise.reject(err);
  }
}

async function put(url, accessToken, data) {
  try {
    const response = await axiosInstance.put(
      url,
      data,
      axiosFormHeaderConfig(accessToken)
    );

    return Promise.resolve(response.data);
  } catch (err) {
    // console.log(`ERROR FROM PUTTING DATA : ${err.message}`);
    return Promise.reject(err);
  }
}

// prefixed with underscored because delete is a reserved word in javascript
async function _delete(url, accessToken, data) {
  try {
    const response = await axiosInstance.put(
      url,
      data,
      axiosHeaderConfig(accessToken)
    );

    return Promise.resolve(response.data);
  } catch (err) {
    // console.log(`ERROR FROM DELETING DATA : ${err.message}`);
    return Promise.reject(err);
  }
}

function fetcher(url, accessToken) {
  return get(url, token);
}
