const SEOConfig = {
  titleTemplate: "%s | Official Asante Kotoko Website",
  defaultTitle: "Asante Kotoko SC | Official Asante Kotoko Website",
  description:
    "The official website of Asante Kotoko Sporting Club, with team news, live match updates, player profiles, merchandise, ticket information and more.",
  canonical: "https://scasantekotoko.com/",
  openGraph: {
    type: "website",
    locale: "en_US",
    url: "https://scasantekotoko.com/",
    site_name: "Asante Kotoko SC",
  },
  twitter: {
    handle: "@AsanteKotoko_SC",
    site: "@AsanteKotoko_SC",
    cardType: "summary_large_image",
  },
};

export default SEOConfig;
