// NextAuth URLS
export const NEXT_AUTH_LOGIN_URL = "/accounts/login";
export const NEXT_AUTH_LOGOUT_URL = "/signOut";

// COMMON URLS
export const INDEX_URL = "/";

// USER URLS
export const DASHBOARD_URL = "/accounts/dashboard";
export const MY_KOTOKO_URL = "/accounts/mykotoko";
export const USER_PROFILE_UPDATE = "/accounts/settings";
export const WEBSITE_THEME_SETTINGS = "/accounts/theme";

// WEBSITE URLS
export const KOTOKO_TV_URL = "https://www.ceek.com/channel/41/";
export const TICKETS_URL = "https://ticketlake.com/";
export const SHOP_URL = "https://www.errea.com/replica/asante-kotoko-s-c";
export const FABU_CENSUS_URL = "https://fabucensus.com/";
export const KOTOKO_APP_PLAY_STORE_URL =
  "https://play.google.com/store/apps/details?id=com.kotoko.express";
export const KOTOKO_APP_APP_STORE_URL =
  "https://apps.apple.com/us/app/kotoko-express-app/id1462573872";

// POLLS URLS
export const POLL_LIST_URL = "/polls";
export const PLAYER_POLL_URL = "/player-polls";
export const TEXT_POLL_URL = "/text-polls";

// NEWS URLS
export const NEWS_LIST_URL = "/news";

// GALLERIES URL
export const GALLERIES_LIST_URL = "/galleries";

// FAQs
export const FAQs = "/faqs";

// LEGAL URLS
export const TERMS_AND_CONDITIONS_URL = "/terms-and-conditions";
export const PRIVACY_POLICY_URL = "/privacy-policy";
export const CONTACT_US_URL = "/contact-us";

// PEOPLE URLS
export const PLAYER_LIST_URL = "/players";
export const PEOPLE_LIST_URL = "/people";
export const BOARD_DETAIL_URL = "board";
export const MANAGEMENT_DETAIL_URL = "management";
export const TECHNICAL_DETAIL_URL = "technical";

// HISTORY URLS
export const KOTOKO_HISTORY_URL = "/history";
export const LEGEND_LIST_URL = "/legends";
export const TROPHIES_URL = "/trophies";

// CLUB URLS
export const CLUB_URL = "/club";
export const FANS_URL = "/fans";

// FIXTURES AND STANDINGS URLS
export const FIXTURES_URL = "/fixtures-and-results";
export const STANDINGS_URL = "/competition-standings";

// LIVE MATCH URLS
export const LIVE_MATCH_URL = "/matches";

// ARCHIVES URLS
export const ARCHIVES_URL = "/archives";
