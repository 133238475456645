import CookieConsent from "react-cookie-consent";

const WebsiteCookieConsent = () => {
  const origin =
    typeof window !== "undefined" && window.location.origin
      ? window.location.origin
      : "";

  const privacyPolicyUrl = origin + "/privacy-policy";

  return (
    <CookieConsent
      enableDeclineButton
      flipButtons
      location="bottom"
      buttonText="Accept"
      declineButtonText="Decline"
      cookieName="asanteKotokoWebsiteCookie"
      style={{ background: "#111827", color: "#f9fafb", fontSize: "13px" }}
      buttonStyle={{
        background: "#dc2626",
        color: "#f9fafb",
        fontSize: "13px",
        borderRadius: "5px",
      }}
      declineButtonStyle={{
        background: "#111827",
        color: "#dc2626",
        fontSize: "13px",
      }}
      expires={30}
    >
      Asante Kotoko uses first and third-party cookies to improve your browsing
      experience and for analytical purpose. By using our website you agree to
      use of cookies as described in our{" "}
      <a
        className="underline"
        target="_blank"
        rel="noopener noreferrer"
        href={privacyPolicyUrl}
      >
        Privacy Policy
      </a>
    </CookieConsent>
  );
};

export default WebsiteCookieConsent;
