import { AppStoreButton, PlayStoreButton } from "./buttons/AppStoreButtons";

const AppStoreIcons = () => {
  return (
    <>
      <section className="mt-10">
        <h3 className="text-center font-extrabold text-lg">Download our app</h3>

        <div className="max-w-md mx-auto flex flex-col items-center space-y-6 sm:space-y-0 sm:space-x-6 sm:flex sm:flex-row sm:justify-center">
          <PlayStoreButton />

          <AppStoreButton />
        </div>
      </section>
    </>
  );
};

export default AppStoreIcons;
