import Image from "next/legacy/image";
import Link from "next/link";

import porcupine_icon from "../../../public/porcupine_icon.png";
import {
  ARCHIVES_URL,
  CLUB_URL,
  CONTACT_US_URL,
  FANS_URL,
  FAQs,
  GALLERIES_LIST_URL,
  KOTOKO_HISTORY_URL,
  LEGEND_LIST_URL,
  NEWS_LIST_URL,
  PEOPLE_LIST_URL,
  PLAYER_LIST_URL,
  POLL_LIST_URL,
  PRIVACY_POLICY_URL,
  STANDINGS_URL,
  TERMS_AND_CONDITIONS_URL,
  TROPHIES_URL,
} from "../../utils/constants/websiteUrls";

const currentYear = new Date().getFullYear();

function Footer() {
  const menuItems1 = [
    { name: "News", href: NEWS_LIST_URL, openBlank: false },
    {
      name: "Galleries",
      href: GALLERIES_LIST_URL,
      openBlank: false,
    },
    { name: "Polls", href: POLL_LIST_URL, openBlank: false },
    { name: "Standings", href: STANDINGS_URL, openBlank: false },
  ];

  const menuItems2 = [
    { name: "Players", href: PLAYER_LIST_URL, openBlank: false },
    {
      name: "People",
      href: PEOPLE_LIST_URL,
      openBlank: false,
    },
    // { name: "Legends", href: LEGEND_LIST_URL, openBlank: false },
    { name: "Fans", href: FANS_URL, openBlank: false },
  ];

  const menuItems3 = [
    { name: "Club", href: CLUB_URL, openBlank: false },
    { name: "History", href: KOTOKO_HISTORY_URL, openBlank: false },
    { name: "Trophies", href: TROPHIES_URL, openBlank: false },
    { name: "Archives", href: ARCHIVES_URL, openBlank: false },
  ];

  const menuItems4 = [
    { name: "FAQs", href: FAQs, openBlank: false },
    {
      name: "Privacy",
      href: PRIVACY_POLICY_URL,
      openBlank: false,
    },
    { name: "Terms", href: TERMS_AND_CONDITIONS_URL, openBlank: false },
    { name: "Contact Us", href: CONTACT_US_URL, openBlank: false },
  ];

  const copyright = `© ${currentYear} Asante Kotoko Sporting Club Limited. All rights reserved.`;

  return (
    <>
      <footer>
        <div className="max-w-7xl mx-auto py-10 px-8 sm:px-6 lg:px-8">
          <div className="border-t border-black pt-8"></div>
          <div className="xl:grid xl:grid-cols-3 gap-0 pb-8">
            <div className="space-y-8 xl:col-span-1 flex justify-center">
              <Image
                objectFit="contain"
                src={porcupine_icon}
                alt="Kotoko Logo Black"
                width={120}
                height={100}
                quality={100}
                placeholder="blur"
              />
            </div>
            <div className="mt-12 grid grid-cols-2 xl:mt-0 xl:col-span-2">
              <div className="md:grid md:grid-cols-2 md:gap-2">
                <div className="flex justify-center">
                  <ul role="list" className="mt-4 space-y-4">
                    {menuItems1.map((menuItem) => (
                      <li key={menuItem.name}>
                        <Link
                          href={menuItem.href}
                          className="text-base text-black hover:text-gray-500"
                          target={menuItem.openBlank ? "_blank" : ""}
                          rel={menuItem.openBlank ? "noopener noreferrer" : ""}
                        >
                          {menuItem.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt-12 md:mt-0 flex justify-center">
                  <ul role="list" className="mt-4 space-y-4">
                    {menuItems2.map((menuItem) => (
                      <li key={menuItem.name}>
                        <Link
                          href={menuItem.href}
                          className="text-base text-black hover:text-gray-500"
                          target={menuItem.openBlank ? "_blank" : ""}
                          rel={menuItem.openBlank ? "noopener noreferrer" : ""}
                        >
                          {menuItem.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="md:grid md:grid-cols-2 md:gap-2">
                <div className="flex justify-center">
                  <ul role="list" className="mt-4 space-y-4">
                    {menuItems3.map((menuItem) => (
                      <li key={menuItem.name}>
                        <Link
                          href={menuItem.href}
                          className="text-base text-black hover:text-gray-500"
                          target={menuItem.openBlank ? "_blank" : ""}
                          rel={menuItem.openBlank ? "noopener noreferrer" : ""}
                        >
                          {menuItem.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt-12 md:mt-0 flex justify-center">
                  <ul role="list" className="mt-4 space-y-4">
                    {menuItems4.map((menuItem) => (
                      <li key={menuItem.name}>
                        <Link
                          href={menuItem.href}
                          className="text-base text-black hover:text-gray-500"
                          target={menuItem.openBlank ? "_blank" : ""}
                          rel={menuItem.openBlank ? "noopener noreferrer" : ""}
                        >
                          {menuItem.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="border-t border-black pt-8"></div>
          <div>
            <p className="text-base text-center text-black">{copyright}</p>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
