import { NextSeo } from "next-seo";
import {
  kotoko_logo_og_image_card,
  kotoko_logo_twitter_image_card,
} from "../constants/images";
import {
  ATTACKER,
  DEFENDER,
  GOALKEEPER,
  LEGEND,
  MEN,
  MIDFIELDER,
  UNDER17,
  UNDER20,
  WOMEN,
} from "../constants/names";

export function axiosHeaderConfig(accessToken) {
  let config = {
    headers: {
      Authorization: `Token ${accessToken}`,
    },
  };
  return config;
}

export function axiosFormHeaderConfig(accessToken) {
  let config = {
    headers: {
      Authorization: `Token ${accessToken}`,
      "Content-Type": "multipart/form-data",
    },
  };
  return config;
}

export function titleCase(str) {
  return str
    .toLowerCase()
    .split(" ")
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
}

// GREETINGS
export const timeGreetingsAutoDetectTime = () => {
  const messages = {
    MORNING: "Good morning",
    AFTERNOON: "Good afternoon",
    EVENING: "Good evening",
    NIGHT: "Good night",
    ERROR:
      "Date passed is in the wrong format or you did not pass the date to the function call!",
  };

  const date_time = new Date();
  const hours = date_time.getHours();

  if (hours >= 0 && hours < 12) {
    return messages.MORNING;
  } else if (hours >= 12 && hours < 17) {
    return messages.AFTERNOON;
  } else if (hours >= 17 && hours < 20) {
    return messages.EVENING;
  } else {
    return messages.NIGHT;
  }
};

export const checkIfObjectValuesAreEmpty = (obj) =>
  Object.values(obj).every((x) => x === null || x === "");

// SEO

export const customRobotSeoConfig = (title) => {
  return (
    <NextSeo
      title={title}
      robotsProps={{
        nosnippet: false,
        notranslate: false,
        noimageindex: false,
        noarchive: true,
        maxSnippet: -1,
        maxImagePreview: "standard",
        maxVideoPreview: -1,
      }}
    ></NextSeo>
  );
};

export const customSeoConfig = (title, caption, thumbnail, url, type) => {
  return (
    <NextSeo
      title={title}
      description={caption}
      robotsProps={{
        nosnippet: false,
        notranslate: false,
        noimageindex: false,
        noarchive: true,
        maxSnippet: -1,
        maxImagePreview: "standard",
        maxVideoPreview: -1,
      }}
      openGraph={{
        title: title,
        description: caption,
        url: url,
        type: type,
        images: [
          {
            url: thumbnail,
            alt: "Thumbnail",
          },
        ],
      }}
    ></NextSeo>
  );
};

export const customStaticSeoConfig = (title) => {
  return (
    <NextSeo
      title={title ? title : ""}
      robotsProps={{
        nosnippet: false,
        notranslate: false,
        noimageindex: false,
        noarchive: true,
        maxSnippet: -1,
        maxImagePreview: "standard",
        maxVideoPreview: -1,
      }}
      openGraph={{
        images: [
          {
            url: kotoko_logo_twitter_image_card,
          },
          {
            url: kotoko_logo_og_image_card,
          },
        ],
      }}
    ></NextSeo>
  );
};

// PLAYERS
export const getPlayerGroup = (player) => {
  if (player.player_group === MEN) {
    return "Men";
  }

  if (player.player_group === WOMEN) {
    return "Women";
  }

  if (player.player_group === UNDER20 || player.player_position === UNDER17) {
    return "Academy";
  }

  if (player.player_group === LEGEND) {
    return "Legend";
  }
};

export const getPlayerPosition = (playerPosition) => {
  if (playerPosition === GOALKEEPER) {
    return "Goalkeeper";
  }

  if (playerPosition === DEFENDER) {
    return "Defender";
  }

  if (playerPosition === MIDFIELDER) {
    return "Midfielder";
  }

  if (playerPosition === ATTACKER) {
    return "Attacker";
  }
};
