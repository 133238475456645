import React from "react";

import * as Sentry from "@sentry/nextjs";
import Link from "next/link";
import Image from "next/legacy/image";

import NoTextNavBackground from "../common/NoTextNavBackground";
import { asanteKotokoLogo } from "../../utils/constants/images";
import { INDEX_URL } from "../../utils/constants/websiteUrls";
import RightArrowIcon from "../common/icons/RightArrowIcon";
import { customStaticSeoConfig } from "../../utils/helpers/helpers";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI

    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    // You can use your own error logging service here
    Sentry.captureException(errorInfo);
    Sentry.captureException(error.message);
  }
  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <>
        {customStaticSeoConfig("Client Error")}

        <NoTextNavBackground />

        <main className="relative py-[120px]  sm:py-[275px] md:py-[220px]">
          <div className="max-w-screen-xl mx-auto pb-6 px-4 sm:px-6 lg:pb-16 lg:px-8">
            <div className="flex-shrink-0 flex justify-center items-center">
              <Link href={INDEX_URL} className="inline-flex">

                <span className="sr-only">Asante Kotoko SC</span>
                <div className="relative h-12 w-12">
                  <Image
                    src={asanteKotokoLogo}
                    alt="asante kotoko logo"
                    layout="fill"
                    objectFit="contain"
                    quality={100}
                    priority
                  />
                </div>

              </Link>
            </div>
            <div className="py-10">
              <div className="text-center">
                <p className="text-sm font-semibold text-red-600 dark:text-green-800 uppercase tracking-wide">
                  Client error
                </p>
                <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                  A problem occurred.
                </h1>
                <p className="mt-2 text-base text-gray-500">
                  Oops, there is an error!
                </p>
                <div className="mt-6">
                  <Link
                    href={INDEX_URL}
                    onClick={() => this.setState({ hasError: false })}
                    className="text-base font-medium text-red-600 hover:text-red-500 dark:text-green-800  dark:hover:dark:text-green-700">
                    Try again?<span aria-hidden="true">
                      <RightArrowIcon />
                    </span>

                  </Link>
                </div>
              </div>
            </div>
          </div>
        </main>
      </>;
    }

    // Return children components in case of no error

    return this.props.children;
  }
}

export default ErrorBoundary;
