import Link from "next/link";

import { GrFacebook, GrInstagram, GrTictok, GrTwitter } from "react-icons/gr";
import { SiTiktok } from "react-icons/si";

const SocialMediaIcons = () => {
  return <>
    <section>
      <div className="max-w-7xl mx-auto pt-20 px-4 sm:pt-10 sm:px-6 lg:px-8">
        <div className="relative">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-black"></div>
          </div>
          <div className="relative flex justify-center">
            <span className="px-3 bg-gray-50 text-lg font-medium text-black">
              #porcupinewarriors
            </span>
          </div>
        </div>
        <div className="mt-10 flex justify-center space-x-6">
          <Link
            href="https://www.facebook.com/AsanteKotokoSportingClub/"
            className="text-black hover:text-gray-500"
            target="_blank"
            rel="noopener noreferrer">

            <span className="sr-only">Facebook</span>
            <GrFacebook size={35} />

          </Link>

          <Link
            href="https://www.instagram.com/scasantekotoko/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-black hover:text-gray-500">

            <span className="sr-only">Instagram</span>
            <GrInstagram size={35} />

          </Link>

          <Link
            href="https://twitter.com/AsanteKotoko_SC"
            className="text-black hover:text-gray-500"
            target="_blank"
            rel="noopener noreferrer">

            <span className="sr-only">Twitter</span>
            <GrTwitter size={35} />

          </Link>

          <Link
            href="https://www.tiktok.com/@asante_kotoko"
            className="text-black hover:text-gray-500"
            target="_blank"
            rel="noopener noreferrer">

            <span className="sr-only">TikTok</span>
            <SiTiktok size={35} />

          </Link>
        </div>
      </div>
    </section>
  </>;
};

export default SocialMediaIcons;
