/* eslint-disable @next/next/no-before-interactive-script-outside-document */
import { DefaultSeo } from "next-seo";
import { SessionProvider } from "next-auth/react";
import Script from "next/script";
import Head from "next/head";

import "focus-visible";
import { SWRConfig } from "swr";

import "../../styles/tailwind.css";
import "../../styles/markdown.css";
import "../../styles/keenSlider.css";

import SiteLayout from "../components/layouts/SiteLayout";
import SEOConfig from "../../next-seo.config";

import ErrorBoundary from "../components/layouts/ErrorBoundary";
import { apiWrapper } from "../utils/api/apiWrapper";

function MyApp({ Component, pageProps: { session, ...pageProps } }) {
  return (
    <ErrorBoundary>
      {/**      // Re-fetch session if cache is older than 2 hours */}
      <SessionProvider session={session} refetchInterval={2 * 60 * 60}>
        <SWRConfig
          value={{
            revalidateOnMount: false,
            revalidateOnFocus: false,
            fetcher: (resource, header) => apiWrapper.get(resource, header),
          }}
        >
          <SiteLayout>
            <DefaultSeo {...SEOConfig} />

            <Head>
              <meta
                name="viewport"
                content="initial-scale=1.0, width=device-width"
              />
            </Head>

            <Component {...pageProps} />
          </SiteLayout>
        </SWRConfig>
      </SessionProvider>
    </ErrorBoundary>
  );
}

export default MyApp;
