// Production
export const kAsanteKotokoPortalApiBaseUrl =
  "https://asantekotokoportal.com/api/v1";

/// URLS
// Accounts
export const kSignUpURL = "/dj-rest-auth/registration/";
export const kLogInURL = "/dj-rest-auth/login/";
export const kLogOutURL = "/dj-rest-auth/logout/";
export const kPasswordResetURL = "/dj-rest-auth/password/reset/";
export const kPasswordChangeURL = "/dj-rest-auth/password/change/";
export const kUserProfileURL = "/dj-rest-auth/user/";

// Social Auth
export const kFacebookAuthURL = "/dj-rest-auth/facebook/";
export const kGoogleAuthURL = "/dj-rest-auth/google/";
export const kAppleAuthURL = "/dj-rest-auth/apple/";

// Profile
export const kUserProfileCreateURL = "/accounts/profile/create/";
export const kIsUserProfileCreatedURL = "/accounts/profile/created/";
export const kUserProfileUpdateURL = "/accounts/profile/update/";
export const kUserFavoritePlayerCreateURL = "/accounts/favorite/player/create/";
export const kUserFavoritePlayerUpdateURL = "/accounts/favorite/player/update/";
export const kUserSignForKotokoCreateURL = "/accounts/sign-for-kotoko/create/";
export const kUserSignForKotokoUpdateURL = "/accounts/sign-for-kotoko/update/";
export const kUserRecentActivityURL = "/accounts/recent/activity/";

// Players
export const kKotokoPlayersFavoritesListURL = "/players/favorites/";
export const kKotokoPlayersMenListURL = "/players/men/";
export const kKotokoPlayersWomenListURL = "/players/women/";
export const kKotokoPlayersLegendsListURL = "/players/legends/web/";
export const kKotokoPlayersUnder20ListURL = "/players/under-20s/";
export const kKotokoPlayersUnder17ListURL = "/players/under-17s/";

export const kKotokoPlayerListURL = "/players/";
export const kKotokoPlayerDetailURL = "/players/detail/";

// News
export const kLatestNewsListURL = "/news/latest/";
export const kClubNewsListURL = "/news/club/";
export const kMenNewsListURL = "/news/men/";
export const kWomenNewsListURL = "/news/women/";
export const kUnder20NewsListURL = "/news/under-20/";
export const kUnder17NewsListURL = "/news/under-17/";
export const kRecommendedNewsListURL = "/news/recommended/";
export const kNewsDetailURL = "/news/detail/";
export const kNewsTotalViewsUpdateURL = "/news/total/views/update/";
export const kNewsSearchURL = "/news/search/";

// Videos
export const kLatestVideosListURL = "/videos/latest/";
export const kClubVideosListURL = "/videos/club/";
export const kMenVideosListURL = "/videos/men/";
export const kWomenVideosListURL = "/videos/women/";
export const kUnder20VideosListURL = "/videos/under-20/";
export const kUnder17VideosListURL = "/videos/under-17/";
export const kRecommendedVideosListURL = "/videos/recommended/";
export const kVideoDetailURL = "/videos/detail/";
export const kVideoTotalViewsUpdateURL = "/videos/total/views/update/";
export const kVideosSearchURL = "/videos/search/";

// Galleries
export const kLatestGalleriesListURL = "/galleries/latest/";
export const kClubGalleriesListURL = "/galleries/club/";
export const kMenGalleriesListURL = "/galleries/men/";
export const kWomenGalleriesListURL = "/galleries/women/";
export const kUnder20GalleriesListURL = "/galleries/under-20/";
export const kUnder17GalleriesListURL = "/galleries/under-17/";
export const kGalleryDetailURL = "/galleries/detail/";
export const kGalleryTotalViewsUpdateURL = "/galleries/total/views/update/";
export const kGalleriesSearchURL = "/galleries/search/";

// Adverts
export const kAdvertImagesURL = "/adverts/images/";

// Help
export const kFAQsURL = "/faqs/";
export const kContactUsURL = "/contact/us/";
export const kAppFeedbackURL = "/app/feedback/";

// FAQs & Terms and Conditions
export const kPrivacyPolicyURL = "/privacy/policy/";
export const kTermsConditionsURL = "/terms/conditions/";

// History
export const kClubHistoryURL = "/club/history/detail/";
export const kClubTrophiesURL = "/trophies/detail/";

// Sponsors
export const kSponsorsURL = "/sponsors/";

// Shop
export const kShopURL = "/shop/detail/";

// People
export const kTechnicalTeamMembersURL = "/people/technical/teams/";
export const kManagementMembersURL = "/people/management/members/";
export const kBoardMembersURL = "/people/board/members/";
export const kLifePatronURL = "/people/life/patron/";

export const kTechnicalTeamMemberDetailURL = "/people/technical/teams/detail/";
export const kManagementMemberDetailURL = "/people/management/members/detail/";
export const kBoardMemberDetailURL = "/people/board/members/detail/";

// Kotoko Now Feed
export const kAsanteKotokoFeedURL = "/asante/kotoko/now/feed/";
export const kKotokoNowFeedSearchURL = "/kotoko/now/feed/search/";
export const kFeedSearchURL = "/feed/search/";

// Websites
export const kWebsitesURL = "/websites/url/";

// Tables
export const kAllTablesURL = "/tables/all/";
export const kMenTablesURL = "/tables/men/";
export const kWomenTablesURL = "/tables/women/";
export const kUnder20TablesURL = "/tables/under-20s/";
export const kUnder17TablesURL = "/tables/under-17s/";

// Fixtures
export const kAllFixturesURL = "/fixtures/all/";
export const kMenFixturesURL = "/fixtures/men/paginated/";
export const kWomenFixturesURL = "/fixtures/women/paginated/";
export const kUnder20FixturesURL = "/fixtures/under-20s/paginated/";
export const kUnder17FixturesURL = "/fixtures/under-17s/paginated/";

// Results
export const kMenFixturesResultURL = "/fixtures/men/results/";
export const kWomenFixturesResultURL = "/fixtures/women/results/";
export const kUnder20FixturesResultURL = "/fixtures/under-20s/results/";
export const kUnder17FixturesResultsURL = "/fixtures/under-17s/results/";

// Kotoko TV
export const kKotokoTVURL = "/kotoko/tv/";

// Polls
export const kNewPollListURL = "polls/new/list/";
export const kNewPollNotification = "polls/new/notification/";
export const kPlayerPollListURL = "/player/polls/new/";
export const kTextPollListURL = "/text/polls/new/";
export const kPlayerPollEndURL = "/player/polls/end/";
export const kTextPollEndURL = "/text/polls/end/";
export const kPlayerPollDetailURL = "/player/polls/detail/";
export const kTextPollDetailURL = "/text/polls/detail/";
export const kPlayerPollsVotesAndOptionsUpdateURL =
  "/player/polls/total/votes/update/";
export const kTextPollsVotesAndOptionsUpdateURL =
  "/text/polls/total/votes/update/";

// Match Event
export const kUpcomingLiveMatchURL = "/live/matches/upcoming/";
export const kLiveMatchDetailURL = "/live/matches/detail/";

// Next Auth Session
export const kNextAuthSessionUrl = "/api/auth/session";

// Archieves
export const kPastSeasonListURL = "/seasons/past/";
export const kSeasonDetailURL = "/seasons/detail/";
