// SOCIAL PROVIDERS
export const GOOGLE_PROVIDER = "google";
export const APPLE_PROVIDER = "apple";
export const FACEBOOK_PROVIDER = "facebook";

// GUEST TOKEN
export const GUEST_TOKEN = "8741c3e5e6a4c027f2371db4f15f6accebc4bade";

// PLAYER POSITION
export const GOALKEEPER = "GOALKEEPER";
export const DEFENDER = "DEFENDER";
export const MIDFIELDER = "MIDFIELDER";
export const ATTACKER = "ATTACKER";

// PLAYER GROUP
export const MEN = "MEN";
export const WOMEN = "WOMEN";
export const UNDER17 = "UNDER17";
export const UNDER20 = "UNDER20";
export const ACADEMY = "ACADEMY";
export const LEGEND = "LEGEND";

// ITEM GROUPS
export const news = "news";
export const gallery = "gallery";
export const textPoll = "text_poll";
export const playerPoll = "player_poll";

// LIVE MATCH
export const LIVE_MATCH_UPCOMING_STATUS = "UPCOMING";
export const LIVE_MATCH_PENALTIES_STATUS = "PENALTIES";
export const LIVE_MATCH_FIRST_HALF_KICK_OFF_STATUS = "FIRST_HALF_KICK_OFF";
export const LIVE_MATCH_HALF_TIME_STATUS = "HALF_TIME";
export const LIVE_MATCH_SECOND_HALF_KICK_OFF_STATUS = "SECOND_HALF_KICK_OFF";
export const LIVE_MATCH_FULL_TIME_STATUS = "FULL_TIME";
export const LIVE_MATCH_FIRST_EXTRA_TIME_STATUS = "FIRST_EXTRA_TIME";
export const LIVE_MATCH_SECOND_EXTRA_TIME_STATUS = "SECOND_EXTRA_TIME";
export const LIVE_MATCH_GOAL_EVENT = "GOAL";

export const REAL_TIME_LIVE_MATCH_DATABASE = "live_matches";
export const LIVE_MATCH_COMMENTARY_PATH = "commentaries";
export const LIVE_MATCH_CHATS_PATH = "chats";
export const LIVE_MATCH_FIXTURES_PATH = "fixtures";
export const LIVE_MATCH_LINE_UPS_PATH = "lineups";
export const LIVE_MATCH_STATS_PATH = "stats";

export const IMAGE_POST = "IMAGE_POST";
export const TEXT_POST = "TEXT_POST";
export const FIRST_HALF_KICK_OFF = "FIRST_HALF_KICK_OFF";
export const HALF_TIME = "HALF_TIME";
export const SECOND_HALF_KICK_OFF = "SECOND_HALF_KICK_OFF";
export const FULL_TIME = "FULL_TIME";
export const KOTOKO_GOAL = "KOTOKO_GOAL";
export const OPPONENT_GOAL = "OPPONENT_GOAL";
export const PENALTY = "PENALTY";
export const RED_CARD = "RED_CARD";
export const YELLOW_CARD = "YELLOW_CARD";
export const SUBSTITUTION = "SUBSTITUTION";
export const VAR_CHECK = "VAR_CHECK";
export const FIRST_EXTRA_TIME = "FIRST_EXTRA_TIME";
export const SECOND_EXTRA_TIME = "SECOND_EXTRA_TIME";
export const ADDED_TIME = "ADDED_TIME";

export const kAT = "AT";
export const kHT = "HT";
export const kFT = "FT";
export const kET = "ET";
export const kPK = "PK";
