import Image from "next/legacy/image";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import NoTextNavBackground from "./NoTextNavBackground";
import { porcupine_logo_large } from "../../utils/constants/images";

export const LoadingSpinner = () => {
  return (
    <div className="relative m-10 h-[80px] w-20  flex justify-center items-center mx-auto">
      <div className="w-8 h-5 top-[29px] bottom-[29px] right-[25px] left-[25px] absolute">
        <Image
          src={porcupine_logo_large}
          alt="porcupine logo"
          layout="fill"
          objectFit="contain"
          priority
        />
      </div>

      <div className="absolute bottom-[2px] inset-4 z-10">
        <div className="inline-block w-12 h-12 border-4 rounded-full text-gray-300"></div>
      </div>
      <div className="absolute bottom-[2px] inset-4 z-10">
        <div
          className="spinner-border animate-spin inline-block w-12 h-12 border-4 rounded-full text-red-600 dark:text-green-800"
          role="status"
        ></div>
      </div>
    </div>
  );
};

export const AppLoadingSpinner = () => {
  return (
    <>
      <NoTextNavBackground />

      <main className="relative py-[120px]  sm:py-[275px] md:py-[220px] mb-60 md:mb-[210px] lg:mb-[130px] xl:mb-[290px]">
        <div className="max-w-screen-xl mx-auto pb-6 px-4 sm:px-6 lg:pb-16 lg:px-8">
          <div className="flex items-center justify-center">
            <div className="absolute bottom-[3px] flex items-center justify-center z-10">
              <div className="inline-block w-16 h-16 border-4 rounded-full text-gray-300">
                <div className="absolute bottom-[17px] left-3  flex items-center justify-center">
                  <Image
                    src={porcupine_logo_large}
                    alt="porcupine logo"
                    objectFit="contain"
                    priority
                    height={30}
                    width={42}
                  />
                </div>
              </div>
            </div>
            <div className="absolute bottom-[3px] flex items-center justify-center z-10">
              <div
                className="spinner-border animate-spin inline-block w-16 h-16 border-4 rounded-full text-red-600 dark:text-green-800"
                role="status"
              ></div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default LoadingSpinner;

export const Loading = () => {
  const router = useRouter();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleStart = (url) => url !== router.asPath && setLoading(true);
    const handleComplete = (url) => url === router.asPath && setLoading(false);

    console.log(router.asPath);

    router.events.on("routeChangeStart", handleStart);
    router.events.on("routeChangeComplete", handleComplete);
    router.events.on("routeChangeError", handleComplete);

    return () => {
      router.events.off("routeChangeStart", handleStart);
      router.events.off("routeChangeComplete", handleComplete);
      router.events.off("routeChangeError", handleComplete);
    };
  });

  return loading && <AppLoadingSpinner />;
};

export const KotokoLoadingSpinner = () => {
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="absolute">
        <Image
          src={porcupine_logo_large}
          alt="porcupine logo"
          objectFit="contain"
          priority
          height={30}
          width={42}
        />
      </div>

      <div className="absolute bottom-[3px] z-10 flex items-center justify-center h-screen">
        <div className="inline-block w-16 h-16 border-4 rounded-full text-gray-300"></div>
      </div>
      <div className="absolute bottom-[2px] z-10 flex items-center justify-center h-screen">
        <div
          className="spinner-border animate-spin inline-block w-16 h-16 border-4 rounded-full text-red-600 dark:text-green-800"
          role="status"
        ></div>
      </div>
    </div>
  );
};
