export const asante_kotoko_logo_small = "/asante-kotoko-logo.png";
export const asante_kotoko_logo_svg = "/asante_kotoko_logo.svg";
export const asanteKotokoLogo = "/asante_kotoko_logo.svg";
export const porcupine_logo = "/porcupine_icon.png";
export const porcupine_logo_large = "/porcupine_large.png";
export const asante_kotoko_logo_white = "/asante-kotoko-logo-white.png";
export const defaultUserAvatar = "/user-avatar-image.png";
export const redThemeJersey = "/jersey-light.png";
export const greenThemeJersey = "/jersey-dark.png";
export const blurDataURL =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mPU19auBwACHAEGfo8unwAAAABJRU5ErkJggg==";
export const appStoreBadge = "/appStoreBadge.svg";
export const googlePlayStoreBadge = "/playStoreBadge.svg";
export const loginPageBackgroundImageUrl = "/auth-intro.jpg";

export const fans1 = "/fans1.jpg";
export const fans2 = "/fans2.JPG";
export const fans3 = "/fans3.JPG";
export const fans4 = "/fans4.JPG";
export const fans5 = "/fans5.JPG";
export const artBoard = "/artboard.png";
export const club1 = "/club1.JPG";
export const club7 = "/club7.JPG";
export const trophyCabinet = "/trophy-cabinet.jpg";
export const history1 = "/history.jpg";
export const history2 = "/history1.jpg";
export const stadium1 = "/stadium-1.JPG";
export const stadium2 = "/stadium-2.JPG";
export const legends1 = "/legends.jpg";
export const aj2 = "/aj-2.JPG";
export const kotokoEleven = "/kotoko-eleven.jpg";

export const fullTimeIcon = "/full_time_icon.svg";
export const addedTimeIcon = "/added-time.svg";
export const substitutionIcon = "/change.svg";
export const extraTimeIcon = "/extra-time.svg";
export const halfTimeIcon = "/half-time.svg";
export const kotokoGoalIcon = "/kotoko_goal.svg";
export const opponentGoalIcon = "/opponent-goal.svg";
export const penaltyIcon = "/penalty.svg";
export const redCardIcon = "/red-card.svg";
export const yellowCardIcon = "/yellow_card.svg";
export const varReplayIcon = "/var-replay.svg";
export const refreeWhistleIcon = "/referee-whistle.svg";
export const refreeIcon = "/referee.svg";
export const stadiumIcon = "/stadium.svg";
export const trophyIcon = "/trophy.svg";
export const seasonIcon = "/season.svg";
export const toDoListIcon = "/to-do-list.svg";
export const timerIcon = "/timer.svg";
export const teamIcon = "/team.svg";
export const matchInfoIcon = "/match-info.svg";
export const clockIcon = "/clock.svg";
export const calendarIcon = "/calendar.svg";
export const pinIcon = "/pin.svg";

export const kotoko_favicon =
  "https://kotoko-portal-media.s3.eu-west-2.amazonaws.com/media/logos/asante-kotoko-favicon.ico";
export const kotoko_logo_og_image_card =
  "https://kotoko-portal-media.s3.eu-west-2.amazonaws.com/media/logos/kotoko-og-image.png";
export const kotoko_logo_twitter_image_card =
  "https://kotoko-portal-media.s3.eu-west-2.amazonaws.com/media/logos/kotoko-twitter-image.png";
