import Image from "next/legacy/image";
import Link from "next/link";
import {
  asante_kotoko_logo_white,
  blurDataURL,
} from "../../utils/constants/images";
import { FABU_CENSUS_URL } from "../../utils/constants/websiteUrls";

const FabuCensusAd = ({ fabuCensusUrl }) => {
  return <>
    <section className="mt-16 mb-16 py-28 sm:py-0">
      <div>
        <div className="sm:max-w-1xl px-4 sm:px-5 lg:max-w-3xl mx-auto h-60 sm:h-52 flex flex-col sm:flex-row items-center justify-center">
          <div className="h-full w-[70%] sm:w-[30%] bg-red-900 dark:bg-green-900 p-6 text-center flex flex-col items-center justify-center">
            <Image
              src={asante_kotoko_logo_white}
              width="80px"
              height="80px"
              objectFit="contain"
              alt="Porcupine logo"
              quality={100}
              className="object-center object-contain mx-auto"
              placeholder="blur"
              blurDataURL={blurDataURL}
            />

            <h2 className="text-xl sm:text-2xl lg:text-xl 2xl:text-xl font-extrabold text-white mt-3">
              Fabu Census
            </h2>
            <p className="mt-1 text-xs text-gray-100">
              Become part of the Asante Kotoko family
            </p>
          </div>
          <div className="h-full w-[70%] bg-red-600 dark:bg-green-700 text-center p-6 flex flex-col items-center justify-center">
            <p className="text-xs sm:text-base text-white text-center sm:text-start pt-4">
              Take your support to the next level by becoming an official
              member of the club, get priority access to match tickets, unlock
              exclusive member benefits, as well as a host of other benefits.
            </p>
            <div className="max-w-xs py-8 mx-auto">
              <Link
                href={fabuCensusUrl ? fabuCensusUrl : FABU_CENSUS_URL}
                role="button"
                className="px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-lg text-red-900 dark:text-green-900 bg-white hover:bg-red-100 dark:hover:bg-green-100 sm:px-8"
                target="blank"
                rel="noopener noreferrer">
                
                  Get membership
                
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>;
};

export default FabuCensusAd;
